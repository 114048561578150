@charset "UTF-8";

@import '_variables';

@import 'framework/menu-inline_colors';
@import 'framework/menu-social-links_colors';
@import 'framework/menu-sitemap_colors';
@import 'framework/menu-locales_colors';
@import 'framework/menu-privateaccess_colors';
@import 'framework/menu-main_colors';
@import 'framework/menu-sitemap2_colors';
@import 'framework/forms_colors';
@import 'framework/popin_colors';
@import 'framework/pushs_colors';
@import 'framework/numberCard_colors';
@import 'framework/buttons_colors';
@import 'framework/faq_colors';
@import 'framework/resize-buttons_colors';

@import 'blocks/block-login_colors';
@import 'blocks/block-teaser_colors';
@import 'blocks/block-group_colors';
@import 'blocks/block-sitemap_colors';
@import 'blocks/block-download_colors';
@import 'blocks/block-advantages_colors';
@import 'blocks/block-contactform_colors';
@import 'blocks/block-faq_colors';
@import 'blocks/block-editorial_colors';
@import 'blocks/block-simulation_colors';
@import 'blocks/block-slideshow_colors';
@import 'blocks/block-cookies_colors';
@import 'blocks/block-header_colors';
@import 'blocks/block-slidehome_colors';
@import 'blocks/block-backtotop_colors';

@import 'pages/template_colors';

// ----------------------------------------------------------------
// !!!!!!!!!!!!!!!!!! CAREFULL !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Keys must have to be absolutely unique and NOT NESTED
// These keys must be reported in the java replacer functions
// ----------------------------------------------------------------

$white: #ffffff;
$dark: #263039;

// KEYS -----------------------------------------------------------

// FONT : custom font-family name

// #000000
// #ffffffDEFAULT ($white)
// #ffffffHOVER ($blue, #AF000D)
// #ffffffACTIVE ($blue-light)

// #3E505D (HEADERCOLOR)
// #929292
// #263039

// // MENUMAINDESKTOPLEVEL2COLORDEFAULT (#3E505D)
// #929292 (#9292922)
// // MENUMAINDESKTOPLEVEL2COLORACTIVE (#263039)
// #ffffff (HEADERBGCOLORHOVER2)

// #3E505D (MENUMAINMOBILECOLOR)
// #929292
// #263039
// #ffffff

// // MENUMAINMOBILELEVEL2COLORDEFAULT (#3E505D)
// #929292 (#9292922)
// // MENUMAINMOBILELEVEL2COLORACTIVE (#263039)
// #ffffff (#ffffffHOVER2)

// // SYSTEMCOLORDEFAULT ($dark)
// // LINKCOLORDEFAULT ($dark)
// #AF000D
// #AF000D
// #ffffff - #263039

// #263039 (LOGINBLOCKCOLOR)
// #ffffff (LOGINBLOCKBGCOLOR)
// #b2b2b2 (FORMINPUTBORDERCOLOR)
// #AF000D (FORMINPUTBORDERCOLORACTIVE)
// #ffffff (FORMSUBMITCOLOR)
// #ffffff (FORMSUBMITCOLORHOVER)
// #db0011 (FORMSUBMITBGCOLOR)
// #AF000D (FORMSUBMITBGCOLORHOVER)

// #000000
// COOKIESBGBUTTONCOLOR (deprecated)
// #ffffff


@include template-font(FONT);
@include form-font(FONT);
@include button-font(FONT);




@include template-color(#000000, #ffffff, #3E505D, #ffffff);
@include menu-inline-color(#ffffff, #AF000D);
@include menu-social-links-color(#ffffff, #AF000D,#000000,#000000);
@include menu-sitemap-color(#ffffff, #AF000D, #AF000D);
@include menu-sitemap2-color($dark, #AF000D, #AF000D);
@include menu-locales-color(#3E505D, #929292, #3E505D, #929292);
@include menu-privateaccess-color(#3E505D, #929292, #3E505D, #929292);
@include menu-main-color--mobile(#3E505D, #929292, #263039, #929292, #ffffff, #ffffff);
@include menu-main-color--desktop(#3E505D, #929292, #263039, #929292, #ffffff);
@include burger-btn-color(#3E505D, #929292, #263039);

@include form-input-color(#b2b2b2, #AF000D, $dark);
@include form-submit-color(#ffffff, #db0011, #ffffff, #AF000D);
@include button-color(#ffffff, #db0011, #ffffff, #AF000D);
@include popin-color(transparent,#ffffff,#AF000D);
@include numberCard-color(#b2b2b2,#ffffff,#263039);
@include block--cookies-color(#ffffff,#000000,#ffffff, #db0011, #ffffff, #AF000D);
@include resize-buttons-color($dark,#AF000D);
@include block-header-color(#ffffff, #263039);

@include block--login-form-color(#263039, #ffffff, #AF000D);
@include popin--login-form-color(#263039, transparent, #AF000D);
// @include block--teaser-color(#fff, grey, red);
// @include block--group-color(grey);
// @include block--download-color(#ffffff);
@include block--contact-form-color(#263039, #AF000D, #ffffff);
@include block--sitemap-color($dark, #AF000D, #AF000D, transparent);
@include block--slidehome-color(#ffffff, $dark);
@include block--editorial-color(#ffffff, $dark, #AF000D);
@include block--simulation-color(#263039, #ffffff);
@include block--slideshow-color(#ffffff, $dark,#AF000D);
@include block--backtotop-color--bgcolor(#db0011);
@include block--download-color($dark,$white,#AF000D);
@include block--advantages-color(#263039,#ffffff,#AF000D);
@include block--teaser-color($dark,$white,#AF000D,#db0011);
@include faq-question-color(#263039, #ffffff, #db0011);

// Colors variants
$variants: (
  (000000,#000000,#000000),
  (02505C,#02505C,#013840),
  (dadbdb,#dadbdb,#989999),
  (3E505D,#3E505D,#2b3841),
  (929292,#929292,#666666),
  (263039,#263039,#1a2127),
  (db0011,#db0011,#99000b),
  (AF000D,#AF000D,#7a0009),
  (83000A,#83000A,#5b0007),
  (ffffff,#ffffff,#b2b2b2),
  (727378,#727378,#4f5054)
);

@each $color in $variants {
  $label: nth($color,1);
  $hexa: nth($color,2);
  $hexadarken: nth($color,3);

  @include block--teaser-color--bgcolor($label,$hexa,$hexadarken);
  @include block--teaser-color--color($label,$hexa,#AF000D);
  @include block--teaser-color--bordercolor($label, $hexa);
  @include block--group-color--bgcolor($label,$hexa);
  @include block--download-color--bgcolor($label,$hexa);
  @include block--download-color--color($label,$hexa,#AF000D);
  @include block--advantages-color--bgcolor($label,$hexa);
  @include block--advantages-color--color($label,$hexa,#AF000D);
  @include block--editorial-color--color($label,$hexa,#AF000D);
  @include block--editorial-color--bgcolor($label,$hexa);
  @include block--slideshow-color--bgcolor($label, $hexa);
  @include block--slideshow-color--color($label, $hexa, #AF000D);
  @include block--slidehome-color--bgcolor($label, $hexa);
  @include block--slidehome-color--color($label, $hexa, #AF000D);
  @include resize-buttons-color--hovercolor($label,$hexa);
  @include resize-buttons-color--color($label,$hexa);
  @include faq-question-color--color($label,$hexa);
}

// transparent
@include block--teaser-color--bgcolor('transparent',transparent,transparent);
@include block--teaser-color--bordercolor('transparent',transparent);
@include block--group-color--bgcolor('transparent',transparent);
@include block--download-color--bgcolor('transparent',transparent);
@include block--advantages-color--bgcolor('transparent',transparent);
@include block--editorial-color--bgcolor('transparent',transparent);
@include block--slideshow-color--bgcolor('transparent',transparent);
@include block--slidehome-color--bgcolor('transparent',transparent);
